<template>
  <div class="activeProjects">
    <v-card elevation="2" color="ele" outlined shaped v-for="(proj, i) in projects" :key="i" @click="$router.push('/project/' + proj.id)">
      <v-card-title>{{ proj.name }}<v-spacer /> [{{ proj.identifier }}]</v-card-title>
      <v-card-text>{{ proj.projectPhase.name }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn icon color="primary">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-alert v-if="projects.length <= 0" border="left" color="ele">{{ $t("home.projects.noActiveProjects") }}</v-alert>
  </div>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin, formMixin } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  name: "ActiveProjects",

  mixins: [formatMixin, listMixin, formMixin],

  data() {
    return {
      projects: [],
    };
  },

  props: {},

  computed: {
    ...mapGetters([]),
  },

  created() {
    this.loadPorjects();
  },

  methods: {
    loadPorjects: function () {
      axios
        .get(`/api/app/projects`)
        .then((projs) => {
          this.projects = projs.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
