<template>
  <DefaultLayout>
    <template v-slot:header>
      <h1>{{ $t("home.pageTitle") }}</h1>
    </template>
    <v-container> <ActiveProjects /> </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import ActiveProjects from "@/components/activeprojects.vue";

import { mapGetters } from "vuex";

export default {
  name: "home",
  mixins: [],
  components: {
    DefaultLayout,
    ActiveProjects
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(["userName"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
